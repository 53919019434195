<template lang="pug">
    hr.line
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-line",
    props,
}
</script>

<style lang="stylus" scoped src="./Line.styl"></style>
